import {
  Divider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@fluidtruck/core';
import { CloseIcon } from '@fluidtruck/icons';
import Cookies from 'js-cookie';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { Box, FlexCol, Grid, MidText } from '@/base-components';
import { ItemSearchSelect } from '@/base-components/forms/ItemSearchSelect';
import SearchBar from '@/components/SearchBar';

import { SearchTab, tabStyles } from '../SearchTab';

interface Props {
  handleSetItems: () => void;
  showMobileSearch: boolean;
  setShowMobileSearch: (b: boolean) => void;
}

export const MobileSearchBar = ({
  handleSetItems,
  showMobileSearch,
  setShowMobileSearch,
}: Props) => {
  const { t } = useTranslation(['common', 'search']);
  const {
    query: { isMobileAndSpecificSearchOpen },
  } = useRouter();

  const defaultIndex = Number(!!isMobileAndSpecificSearchOpen);

  const user = Cookies.get('user') || '';

  return showMobileSearch ? (
    <Grid>
      <Box
        onClick={() => setShowMobileSearch(false)}
        display="flex"
        justifyContent="flex-end"
        m="4"
      >
        <MidText>{t('common:button.close')}</MidText>
        <CloseIcon data-test-id="close-button" color="fluidBlue" w="6" h="6" />
      </Box>

      <Divider sx={{ margin: '0 16px', width: 'initial', color: 'gray.400' }} />

      <Tabs defaultIndex={defaultIndex} aria-label="search-mobile-tabs">
        <TabList justifyContent="center" mt={4} height="auto">
          <Tab sx={tabStyles}>{t('search:searchAll.tabs.searchAll')}</Tab>
          <SearchTab isDisabled={!user} />
        </TabList>

        <TabPanels>
          <TabPanel>
            <SearchBar
              handleSetItems={handleSetItems}
              showMobileSearch={showMobileSearch}
              setShowMobileSearch={setShowMobileSearch}
            />
          </TabPanel>
          <TabPanel>
            <FlexCol p={3}>
              {t('search:searchAll.tabs.searchByFleet')}
              <ItemSearchSelect />
            </FlexCol>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Grid>
  ) : (
    <SearchBar
      handleSetItems={handleSetItems}
      showMobileSearch={showMobileSearch}
      setShowMobileSearch={setShowMobileSearch}
    />
  );
};
