import { CloseIcon, EVBadgeIcon, InstantBookIcon } from '@fluidtruck/icons';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';

import { Button, Checkbox } from '@/base-components';
import { Body, Header, SubHeader } from '@/base-components';
import { ItemsContext } from '@/lib/context';

import {
  BodyContainer,
  BookingContainer,
  ClearApplyContainer,
  CloseContainer,
  FilterContainer,
  FilterHeader,
  HeaderContainer,
  InnerContainer,
  InstantContainer,
  ListingsContainer,
  ListingsHeader,
  MobileBookingContainer,
  MobileBookingContainerWrapper,
  MobileButtonContainer,
} from './styles';

const ItemFilter = ({ setShowFilter }) => {
  const { t } = useTranslation(['common', 'ev', 'search']);
  const { applyInfo, searchInfo, updateApplyInfo, updateSearchInfo } =
    useContext(ItemsContext);

  const [showListings, setShowListings] = useState(
    applyInfo?.isInstantBook || false
  );
  const [showEVs, setShowEVs] = useState(applyInfo?.showEV || false);

  const handleClear = () => {
    setShowListings(false);
    setShowEVs(false);
  };

  const handleApply = () => {
    updateApplyInfo({
      isInstantBook: showListings,
      showEV: showEVs,
    });

    updateSearchInfo({
      ...searchInfo,
    });
    setShowFilter(false);
  };

  return (
    <FilterContainer>
      <InnerContainer>
        <FilterHeader>
          <CloseContainer onClick={() => setShowFilter(false)}>
            <SubHeader size={14}>{t('button.close')}</SubHeader>
            <CloseIcon
              data-test-id="close-button"
              color="fluidBlue"
              w="6"
              h="6"
            />
          </CloseContainer>
        </FilterHeader>
        <BookingContainer>
          <ClearApplyContainer>
            <Button
              variant="outline"
              size="md"
              mr={2}
              width="120px"
              onClick={handleClear}
            >
              {t('button.clear')}
            </Button>
            <Button
              size="md"
              className="search-filter-applied"
              width="120px"
              data-test-id="button-apply-filter"
              onClick={handleApply}
            >
              {t('button.apply')}
            </Button>
          </ClearApplyContainer>
          <MobileBookingContainerWrapper>
            <MobileBookingContainer>
              <HeaderContainer>
                <InstantContainer>
                  <Header size={18}>{t('ev:ev')}</Header>
                  <EVBadgeIcon ml={2} />
                </InstantContainer>
              </HeaderContainer>
              <BodyContainer>
                <Body size={14} color="gray" style={{ lineHeight: '18px' }}>
                  {t('search:filters.evSlug')}
                </Body>
              </BodyContainer>
              <ListingsContainer>
                <Checkbox
                  isChecked={showEVs}
                  margin={1}
                  size="lg"
                  onChange={() => setShowEVs(!showEVs)}
                />
                <ListingsHeader size={14}>
                  {t('search:filters.onlyEv')}
                </ListingsHeader>
              </ListingsContainer>
            </MobileBookingContainer>
            <MobileBookingContainer>
              <HeaderContainer>
                <InstantContainer>
                  <Header size={18}>{t('instantBook')}</Header>
                  <InstantBookIcon color="fluidBlue" ml={2} />
                </InstantContainer>
              </HeaderContainer>
              <BodyContainer>
                <Body size={14} color="gray" style={{ lineHeight: '18px' }}>
                  {t('search:filters.instaSlug')}
                </Body>
              </BodyContainer>
              <ListingsContainer>
                <Checkbox
                  data-test-id="filter-only-instant"
                  isChecked={showListings}
                  size="lg"
                  margin={1}
                  onChange={() => setShowListings(!showListings)}
                />
                <ListingsHeader size={14}>
                  {t('search:filters.onlyInsta')}
                </ListingsHeader>
              </ListingsContainer>
            </MobileBookingContainer>
          </MobileBookingContainerWrapper>
        </BookingContainer>
      </InnerContainer>
      <MobileButtonContainer>
        <Button
          isFullWidth
          display={{ base: 'block', lg: 'none' }}
          size="md"
          variant="outline"
          onClick={handleClear}
        >
          Clear
        </Button>
        <Button
          isFullWidth
          size="md"
          mt={6}
          display={{ base: 'block', lg: 'none' }}
          className="search-filter-applied"
          onClick={handleApply}
        >
          {t('button.apply')}
        </Button>
      </MobileButtonContainer>
    </FilterContainer>
  );
};

export default ItemFilter;

ItemFilter.propTypes = {
  setShowFilter: PropTypes.func,
};
