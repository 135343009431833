import styled from '@emotion/styled';
import { Avatar } from '@fluidtruck/core';
import PropTypes from 'prop-types';

import { SubHeader } from '@/base-components';
import { generateMQ } from '@/utils/helpers';

const mq = generateMQ('max');

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  [mq[0]]: {
    paddingLeft: 24,
  },
});

const CardHeader = styled.div({
  display: 'flex',
});

const NameDateContainer = styled.div({
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 20,
});

const ReviewContainer = styled.div({
  padding: '15px 24px 15px 0',
  display: 'flex',
  flexWrap: 'wrap',
  wordBreak: 'break-all',
});

const StyledSpan = styled.span(props => ({
  color: props?.theme?.colors?.black,
}));

const StyledHeader = styled(SubHeader)(props => ({
  color: props?.theme?.colors?.adaGray,
}));

const getInitial = letter => `${letter.slice(0, 1)}.` || '';

const ReviewCard = ({ avatar, reviewDate, first, last, review }) => (
  <StyledContainer>
    <CardHeader>
      <Avatar src={avatar} />
      <NameDateContainer>
        <StyledHeader size={14}>
          {first} {getInitial(last)}
        </StyledHeader>
        <StyledSpan>{reviewDate}</StyledSpan>
      </NameDateContainer>
    </CardHeader>
    <ReviewContainer>{review} </ReviewContainer>
  </StyledContainer>
);

export default ReviewCard;

ReviewCard.propTypes = {
  avatar: PropTypes.string,
  reviewDate: PropTypes.string,
  first: PropTypes.string,
  last: PropTypes.string,
  review: PropTypes.string,
};
