import { chakra } from '@fluidtruck/core';
import moment from 'moment';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import { Divider, GrayText, SubHeading, Text } from '@/base-components';

const DurationContainer = chakra('div', {
  baseStyle: {
    display: 'flex',
    justifyContent: 'space-evenly',
    border: '1px',
    borderColor: 'keylineGray',
    borderRadius: 'base',
    marginBottom: 2,
    gap: 3,
  },
  label: 'DurationContainer',
});

const TimeContainer = chakra('div', {
  baseStyle: {
    textAlign: 'center',
    my: 3,
  },
  label: 'TimeContainer',
});

export const SummaryDurationSearch = ({ pickUp, dropOff }) => {
  const { t } = useTranslation();
  const pickupMoment = moment(pickUp).startOf('h');
  const dropoffMoment = moment(dropOff).startOf('h');

  return (
    <DurationContainer>
      <TimeContainer>
        <GrayText fontSize="md">{t('pickup')}</GrayText>
        <Text mt="2">{pickupMoment.format('MM/DD/YY')}</Text>
        <SubHeading fontWeight="normal">
          {pickupMoment.format('hh:mma')}
        </SubHeading>
      </TimeContainer>
      <chakra.div>
        <Divider orientation="vertical" borderColor="keylineGray" />
      </chakra.div>
      <TimeContainer>
        <GrayText fontSize="md">{t('return')}</GrayText>
        <Text mt="2">{dropoffMoment.format('MM/DD/YY')}</Text>
        <SubHeading fontWeight="normal">
          {dropoffMoment.format('hh:mma')}
        </SubHeading>
      </TimeContainer>
    </DurationContainer>
  );
};

SummaryDurationSearch.propTypes = {
  pickUp: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  dropOff: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

SummaryDurationSearch.defaultProps = {
  pickUp: new Date(),
  dropOff: new Date(),
};

export default SummaryDurationSearch;
