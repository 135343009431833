import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { ItemsContext } from '@/lib/context';

import ManagerCard from '../Reservation/ReservationManager/ManagerCard';
import ItemInfo from './ItemInfo';

const ItemPinContainer = styled.div(() => ({
  label: 'ItemPinContainer',
}));

const ItemPin = ({
  thumbnail,
  title,
  cost,
  fleetNumber,
  isElectric,
  isReservationsPage,
  id,
  distance,
  handleContactSupport,
  instabook,
  isDropOffPastNow,
  isAvailable,
}) => {
  const { selectedItem } = useContext(ItemsContext);
  const itemInfo = {
    cost,
    distance,
    fleetNumber,
    id,
    isElectric,
    thumbnail,
    title,
    instabook,
    isDropOffPastNow,
    isAvailable,
  };

  const isShown = selectedItem === id;

  return (
    <ItemPinContainer data-test-id={`item-pin-${id}`}>
      {isShown && !isReservationsPage && <ItemInfo {...itemInfo} />}
      {isShown && isReservationsPage && (
        <ManagerCard
          {...itemInfo}
          handleContactSupport={handleContactSupport}
        />
      )}
    </ItemPinContainer>
  );
};

export default ItemPin;

ItemPin.propTypes = {
  thumbnail: PropTypes.string,
  title: PropTypes.string,
  cost: PropTypes.number,
  fleetNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  isElectric: PropTypes.bool,
  isReservationsPage: PropTypes.bool,
  id: PropTypes.number,
  distance: PropTypes.number,
  handleContactSupport: PropTypes.func,
  instabook: PropTypes.bool,
  isDropOffPastNow: PropTypes.bool,
};

ItemPin.defaultProps = {
  cost: 0,
  isElectric: false,
  isReservationsPage: false,
  isDropOffPastNow: false,
};
