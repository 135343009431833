import { chakra } from '@fluidtruck/core';

import { Box, Button, FlexCol, FlexRow } from '@/base-components';

export const ItemList = chakra(FlexCol, {
  baseStyle: {
    overflow: 'scroll',
    height: '100%',
    width: { base: '100%', lg: 'auto' },
  },
  label: 'ItemList',
});

export const MapContainer = chakra(Box, {
  baseStyle: {
    height: '100%',
    boxShadow: 'inset',
    overflow: 'hidden',
    width: { base: '100%', lg: 'auto' },
  },
  label: 'MapContainer',
});

export const UpdateContainer = chakra(FlexRow, {
  baseStyle: {
    display: { base: 'flex', lg: 'none' },
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px',
    borderColor: 'gray.400',
    py: 3,
  },
  label: 'UpdateContainer',
});

export const DeliveryUpdateContainer = chakra(FlexRow, {
  baseStyle: {
    display: { base: 'flex', lg: 'none' },
    alignItems: 'center',
    borderBottom: '1px',
    borderColor: 'gray.400',
    py: 3,
    width: '100%',
  },
  label: 'DeliveryUpdateContainer',
});

export const DeliveryContainer = chakra(FlexRow, {
  baseStyle: {
    marginRight: 16,
  },
  label: 'DeliveryContainer',
});

export const FilterButton = chakra(Button, {
  baseStyle: {
    mt: { base: 0, lg: 4 },
    mx: { base: 4, lg: 4 },
  },
  label: 'FilterButton',
});

export const DrawerHeaderSx = {
  pt: 10,
  pl: 10,
  fontSize: '3xl',
  fontWeight: 'bold',
};
