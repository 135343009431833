import styled from '@emotion/styled';
import { chakra, Flex } from '@fluidtruck/core';
import { Divider } from '@material-ui/core';

import { SubHeader } from '@/base-components';
import { Grid } from '@/base-components';
import { heights } from '@/theme';
import { generateMQ } from '@/utils/helpers';

const { topNavMobile, searchBar } = heights;

const mqMax = generateMQ('max');

export const SmallText = styled(SubHeader)(props => ({
  color: props?.theme?.colors?.gray[900],
  display: 'flex',
  alignItems: 'flex-end',
  [mqMax[0]]: {
    fontSize: 14,
  },
}));

export const DividerContainer = styled.div({
  display: 'flex',
  alignItems: 'flex-end',
  marginBottom: 3,
});

export const MileageContainer = styled.div({
  display: 'flex',
  paddingTop: 11,
});

export const StyledDivider = styled(Divider)(props => ({
  backgroundColor: props?.theme?.colors?.gray[700],
  margin: '0 8px',
}));

export const MapContainer = chakra('div', {
  baseStyle: {
    height: '350px',
    width: 'inherit',
  },
  label: 'MapContainer',
});

export const Details = chakra(Grid, {
  baseStyle: {
    alignItems: 'center',
    gridGap: 4,
    alignSelf: 'end',
    mt: 3,
  },
  label: 'Details',
});

export const ItemDetailContainer = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    pb: { base: `${topNavMobile + searchBar}px`, lg: 5 },
    width: '100%',
  },
  label: 'ItemDetailContainer',
});

export const LoadingContainer = chakra(Flex, {
  baseStyle: {
    width: { base: '100%', md: '450px' },
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 360,
  },
  label: 'LoadingContainer',
});
export const ImageCarouselContainer = chakra(Flex, {
  baseStyle: {
    mt: 8,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    mb: 7,
  },
  label: 'ImageCarouselContainer',
});

export const FlexContainer = styled.div({
  display: 'flex',
  paddingTop: 26,
});

export const InnerFlexContainer = styled.div({
  display: 'flex',
  paddingRight: 16,
});
