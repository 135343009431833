import styled from '@emotion/styled';

import { generateMQ } from '@/utils/helpers';

const mq = generateMQ('min');

export const Container = styled.div(props => ({
  backgroundColor: props.variant === 'ghost' ? '#f5f5f5' : '#fff',
  borderBottom: '1px solid #C3D0D9',
  gridColumnGap: 12,
  gridRowGap: 4,
  cursor: 'pointer',
  display: 'grid',
  gridTemplateColumns: '120px 1fr 1fr',
  gridTemplateAreas: [
    '"image title title"',
    '"image fleet fleet"',
    '"features features features"',
    '"distance total total"',
    '"action action action"',
  ].join(' '),
  padding: 20,
  [mq[0]]: {
    gridTemplateColumns: '120px 1fr auto auto',
    gridColumnGap: 12,
    gridRowGap: 4,
    gridTemplateAreas: [
      '"image title title features"',
      '"image fleet fleet features"',
      '"image fleet fleet action"',
      '"image distance total action"',
      '"image distance total action"',
    ].join(' '),
  },
}));

export const Image = styled.div({
  gridArea: 'image',
  '& img': {
    borderRadius: 10,
  },
});

export const Title = styled.div({
  fontSize: 18,
  fontWeight: 700,
  gridArea: 'title',
  lineHeight: '24px',
});

export const Fleet = styled.div({
  fontSize: 16,
  fontWeight: 700,
  gridArea: 'fleet',
  lineHeight: '18px',
});

export const Distance = styled.div({
  fontSize: 14,
  fontWeight: 700,
  gridArea: 'distance',
  lineHeight: '16px',
  minHeight: 14,
  alignSelf: 'flex-end',
});

export const Features = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gridArea: 'features',
  [mq[0]]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
});

export const Feature = styled.div({
  '& span': {
    display: 'none',
  },
  '& svg': {
    width: 20,
    height: 20,
    '&:last-of-type': {
      marginLeft: 8,
    },
  },
  [mq[0]]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '&:first-of-type': {
      marginBottom: 8,
    },
    '& span': {
      display: 'inline',
      fontWeight: 700,
      fontSize: 12,
      lineHeight: '16px',
    },
  },
});

export const Total = styled.div(props => ({
  fontSize: 24,
  fontWeight: 'bold',
  gridArea: 'total',
  lineHeight: '24px',
  textAlign: 'right',
  paddingTop: 8,
  '& div': {
    color: props.theme.colors.gray[900],
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '14px',
  },
}));

export const Action = styled.div({
  gridArea: 'action',
  alignSelf: 'end',
  textAlign: 'end',
  paddingTop: 8,
  [mq[0]]: {
    paddingTop: 0,
  },
});
