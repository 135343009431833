import { EVBadgeIcon, InstantBookIcon } from '@fluidtruck/icons';
import moment from 'moment';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';

import {
  Button,
  Divider,
  FlexCol,
  FlexRow,
  MidText,
  SubHeading,
  Text,
} from '@/base-components';
import { useCart } from '@/hooks';
import { ItemsContext } from '@/lib/context';

import { centsToDollars, distance, formatMoney } from '../../utils/conversions';
import {
  DateContainer,
  DateTimeContainer,
  Features,
  HeaderDivider,
  Image,
  Row,
} from './itemCartCardStyles';

export const ItemCartCard = ({ pickUp, dropOff, item, items }) => {
  const { t } = useTranslation(['common', 'search']);
  const { userLocation } = useContext(ItemsContext);
  const [loading, setLoading] = useState(false);
  const { removeItemFromCart } = useCart();

  const handleRemoveItemFromCart = async () => {
    setLoading(true);
    await removeItemFromCart(item?.id);
    if (item) setLoading(false);
  };

  return (
    <>
      <DateTimeContainer>
        <Row justify="flex-start">
          <DateContainer>{moment(pickUp).format('MM/DD/YY')}</DateContainer>
          <Text margin="auto 3px">{t('at')}</Text>
          <DateContainer>{moment(pickUp).format('h:mm a')}</DateContainer>
          <Divider
            margin="auto 8px"
            h={4}
            borderColor="darkGray"
            orientation="vertical"
          />
          <DateContainer>{moment(dropOff).format('MM/DD/YY')}</DateContainer>
          <Text margin="auto 3px">{t('at')}</Text>
          <DateContainer>{moment(dropOff).format('h:mm a')}</DateContainer>
        </Row>
        <Features>
          {item['is-electric-vehicle'] && <EVBadgeIcon h={5} w={5} />}
          {item.instabook && <InstantBookIcon color="fluidBlue" h={5} w={5} />}
        </Features>
      </DateTimeContainer>
      <HeaderDivider />
      <Row justify="flex-start">
        <Image
          src={item?.pictures?.[0]?.url || '/img/cargo-van-square.png'}
          alt={t('rent')}
        />
        <FlexCol>
          <SubHeading>{item?.title}</SubHeading>
          {!item?.title.includes('#') && (
            <SubHeading fontSize="lg" mt={3}>
              {item?.['fleet-number']}
            </SubHeading>
          )}
        </FlexCol>
      </Row>
      <Row justify="space-between" mt={3}>
        <SubHeading>
          {distance(userLocation, item?.location).toFixed(2)} mi
        </SubHeading>
        <FlexRow justify="flex-end" alignItems="flex-end">
          <MidText color="darkGray" mr={1.5}>
            {t('search:estTotal')}
          </MidText>
          <SubHeading>
            {formatMoney(centsToDollars(item?.['estimated-cost']))}
          </SubHeading>
        </FlexRow>
      </Row>
      <Row position="relative">
        <Button
          isFullWidth
          isLoading={loading}
          mt={3}
          onClick={handleRemoveItemFromCart}
          variant="outline"
        >
          {items.length > 1 ? t('button.remove') : t('button.cancel')}
        </Button>
      </Row>
      {items.length > 1 && (
        <Divider borderColor="keylineGray" width="100%" mt={6} />
      )}
    </>
  );
};

export default ItemCartCard;

ItemCartCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    location: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    'estimated-cost': PropTypes.number,
    'fleet-number': PropTypes.string,
    'is-electric-vehicle': PropTypes.bool,
    instabook: PropTypes.bool,
    pictures: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
      })
    ),
  }),
  pickUp: PropTypes.string,
  dropOff: PropTypes.string,
  items: PropTypes.array.isRequired,
};
