import {
  GoogleMap,
  InfoBox,
  Marker,
  MarkerClusterer,
  MarkerClustererProps,
} from '@react-google-maps/api';
import { MarkerExtended } from '@react-google-maps/marker-clusterer';
import React from 'react';

import { useGoogleMaps } from '@/hooks';
import { ACTIONS } from '@/lib/context/GoogleMaps/reducer/map_actions';
import { base64 } from '@/utils';
import { electricVehicleMarker } from '@/utils/maps/map-icons/electric-vehicle-location-marker';
import { userLocationMarker } from '@/utils/maps/map-icons/user-location-icon';
import { vehicleLocationMarker } from '@/utils/maps/map-icons/vehicle-location-marker';
import { svgToBase64 } from '@/utils/maps/svgToBase64';

import * as Types from './types';
import * as Utils from './utils';
interface MarkersWithTitle extends MarkerExtended {
  title?: string;
}

const options: MarkerClustererProps['options'] = {
  minimumClusterSize: 5,
  styles: [
    {
      url: `${base64},${Utils.ClusterMarkerSvgBtoa('#00B2E3')}`,
      textColor: '#00B2E3',
      textSize: 14,
      fontFamily: 'Nunito Sans',
      height: 54,
      width: 54,
      fontWeight: 'bold',
    },
    {
      url: `${base64},${Utils.ClusterMarkerSvgBtoa('#C9CDCF')}`,
      textColor: '#C9CDCF',
      textSize: 14,
      fontFamily: 'Nunito Sans',
      height: 54,
      width: 54,
      fontWeight: 'bold',
    },
  ],
};

const calculator = (markers: MarkersWithTitle[]) => {
  const someAvailable = markers.some(marker => marker.title === 'Available');
  return {
    title: '',
    text: `${markers.length}`,
    index: someAvailable ? 1 : 2,
  };
};

const MultiVehicleMap: React.VFC<Types.MultiVehicleMapProps> = ({
  userLocation,
  allowZoom,
  onDrag,
  onDragEnd,
  handleZoom,
  selectedItem,
  handlePinCleanup,
  infoBox,
  items,
  handlePinClick,
}) => {
  const currentLocationMarker = {
    url: svgToBase64({ icon: userLocationMarker() }),
  };

  const {
    state: { latLng, isMapLoaded },
    dispatch,
    onLoad,
  } = useGoogleMaps();

  if (!latLng) {
    return null;
  }

  const { BoxContent } = (selectedItem && infoBox) || {};

  // Set minHeight to 650 to avoid map shifting up on smaller screen resolutions
  return (
    <GoogleMap
      id="map-items-search"
      center={latLng}
      mapContainerStyle={{
        width: 'inherit',
        height: 'inherit',
        minHeight: '650px',
      }}
      options={{
        disableDefaultUI: true,
        scrollwheel: allowZoom,
        clickableIcons: false,
        fullscreenControl: false,
        zoomControl: true,
      }}
      zoom={11}
      onLoad={onLoad}
      onTilesLoaded={() => {
        dispatch({ type: ACTIONS.SET_MAP_LOADED, payload: true });
      }}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
      onZoomChanged={handleZoom}
      onUnmount={() => {
        dispatch({ type: ACTIONS.SET_MAP, payload: null });
        dispatch({ type: ACTIONS.SET_MAP_LOADED, payload: false });
      }}
    >
      {selectedItem && infoBox && (
        <InfoBox
          options={{
            closeBoxURL: '',
            alignBottom: true,
            boxStyle: {
              overflow: 'visible',
            },
            disableAutoPan: false,
            pixelOffset: new window.google.maps.Size(0, 40),
            enableEventPropagation: true,
          }}
          position={{
            lat: infoBox.position.lat,
            lng: infoBox.position.lng,
          }}
          onUnmount={handlePinCleanup}
        >
          {BoxContent}
        </InfoBox>
      )}
      {isMapLoaded && (
        <MarkerClusterer
          maxZoom={19}
          imageExtension="png"
          options={options}
          calculator={calculator}
        >
          {clusterer =>
            items?.map(item => {
              const id = item.item.id;
              const isElectric = item.item['is-electric-vehicle'];
              const coordinates = item.coordinates;
              const isAvailable = item.item.isAvailable;
              const pin = {
                url: svgToBase64({
                  icon: isElectric
                    ? electricVehicleMarker(isAvailable)
                    : vehicleLocationMarker(isAvailable),
                }),
              };
              return (
                <Marker
                  key={id}
                  clusterer={clusterer}
                  icon={{ ...pin }}
                  position={coordinates}
                  title={`${isAvailable ? 'Available' : 'Unavailable'}`}
                  onClick={() => handlePinClick(id)}
                />
              );
            })
          }
        </MarkerClusterer>
      )}
      {userLocation && (
        <Marker
          title="marker-user-location"
          position={{ lat: userLocation.lat, lng: userLocation.lng }}
          icon={{ ...currentLocationMarker }}
        />
      )}
    </GoogleMap>
  );
};

export { MultiVehicleMap };
