import styled from '@emotion/styled';
import { EVBadgeIcon, InstantBookIcon } from '@fluidtruck/icons';
import { Card, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import { useContext } from 'react';

import { ampli } from '@/amplitude';
import { Header, SubmitButton } from '@/base-components';
import { useUser } from '@/hooks';
import { ItemsContext } from '@/lib/context';
import { formatMoney } from '@/utils';

const CardContainer = styled.div({
  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  width: 184,
  position: 'absolute',
  bottom: 100,
  boxSizing: 'border-box',
  transform: 'translateX(-50%)',
  zIndex: 1,
});

const StyledCard = styled(Card)({
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
});

const DistanceText = styled(Header)({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'flex-end',
  paddingBottom: 5,
});

const TotalText = styled(Typography)({
  alignItems: 'flex-end',
  color: '#949494',
  display: 'flex',
  fontFamily: 'Nunito Sans',
  fontSize: 14,
  fontWeight: 'normal',
  flexDirection: 'column',
});

const TotalSpan = styled.span(props => ({
  color: props?.theme?.colors?.black,
  fontSize: 16,
  fontWeight: 'bold',
  fontFamily: 'Nunito Sans',
  paddingLeft: 5,
}));

const CardContent = styled.div({
  padding: 9,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'space-between',
  height: '100%',
  justifyContent: 'space-between',
});

const StyledHeader = styled(Header)({
  lineHeight: '105%',
});

const MileageCostContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const Features = styled.div({
  cursor: 'pointer',
  position: 'absolute',
  right: 10,
  top: 10,
  zIndex: 100,
});

const InfoImage = styled.div(props => ({
  background: `url(${props?.src})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: 128,
  minHeight: 128,
}));

const ItemInfo = ({
  cost,
  distance,
  id,
  isElectric,
  thumbnail,
  title,
  instabook,
  isAvailable,
}) => {
  const { updateDetailView } = useContext(ItemsContext);
  const { t } = useTranslation(['common', 'search']);
  const { user } = useUser();
  const formattedCost = formatMoney(cost / 100);

  const handleViewClick = () => {
    ampli.viewVehicleDetails({
      userId: user?.id,
    });
    updateDetailView({ type: 'item', detailViewId: id, isAvailable });
  };

  return (
    <CardContainer data-test-id={`card-item-info-${id}`}>
      <StyledCard>
        <InfoImage src={thumbnail} />
        <Features>
          {isElectric && <EVBadgeIcon h={5} mr={2} w={5} />}
          {instabook && <InstantBookIcon color="fluidBlue" h={5} w={5} />}
        </Features>
        <CardContent>
          <div>
            <StyledHeader size={18}>{title}</StyledHeader>
            <MileageCostContainer>
              <DistanceText size={14}>{distance.toFixed(2)} mi</DistanceText>
              <TotalText>
                <Trans t={t} i18nKey="search:map.itemPinEst">
                  Estimated Total
                  <TotalSpan>{{ cost: formattedCost }}</TotalSpan>
                </Trans>
              </TotalText>
            </MileageCostContainer>
          </div>
          <SubmitButton
            mt={3}
            data-test-id={`button-view-${id}`}
            onClick={handleViewClick}
          >
            {t('button.view')}
          </SubmitButton>
        </CardContent>
      </StyledCard>
    </CardContainer>
  );
};

export default ItemInfo;

ItemInfo.propTypes = {
  cost: PropTypes.number,
  distance: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isElectric: PropTypes.bool,
  thumbnail: PropTypes.string,
  title: PropTypes.string,
  instabook: PropTypes.bool,
};
