import {
  CloseButtonProps,
  DrawerCloseButton as TidalDrawerCloseButton,
  forwardRef,
} from '@fluidtruck/core';
import { useRouter } from 'next/router';

import { onClickAmplitude } from '@/base-components/buttons/Buttons';
import { useUser } from '@/hooks';

export {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
} from '@fluidtruck/core';

export const DrawerCloseButton = forwardRef<CloseButtonProps, 'button'>(
  (props, ref) => {
    const router = useRouter();
    const user = useUser();
    return (
      <TidalDrawerCloseButton
        {...props}
        ref={ref}
        onClick={e => {
          onClickAmplitude({
            buttonText: 'Drawer Close Button',
            pageName: window.document.title,
            entityId: Object.entries(router.query),
            userId: user?.user?.id,
            orgId: user?.user?.['default-organization-id'],
          });
          props.onClick?.(e);
        }}
      />
    );
  }
);
