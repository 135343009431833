import styled from '@emotion/styled';
import { Divider } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Header } from '@/base-components';
import { generateMQ } from '@/utils/helpers';

import ReviewCard from './ReviewCard';

const mq = generateMQ('max');

const StyledContainer = styled.div({
  display: 'flex',
  paddingTop: 48,
  [mq[0]]: {
    padding: '24px 0 0 24px',
  },
});

const StyledDivider = styled(Divider)(props => ({
  color: props?.theme?.colors?.gray[400],
  margin: '24px 0',
  height: 1,
  [mq[0]]: {
    width: '100%',
  },
}));

const ReviewsContainer = ({ reviewInfo, noHeader }) => {
  const cards = reviewInfo?.map(review => {
    const time = moment(review?.['inserted-at']).format('LL');

    return (
      <ReviewCard
        key={review?.ID}
        avatar={review?.reviewer?.['avatar-url']}
        reviewDate={time}
        first={review?.reviewer?.first}
        last={review?.reviewer?.last}
        review={review?.['public-comment']}
      />
    );
  });

  return (
    <>
      {!noHeader && (
        <StyledContainer>
          <Header size={24}>Reviews</Header>
        </StyledContainer>
      )}
      <StyledDivider orientation="horizontal" variant="middle" />
      {cards}
    </>
  );
};

export default ReviewsContainer;

ReviewsContainer.propTypes = {
  reviewInfo: PropTypes.oneOfType([PropTypes.array]),
  noHeader: PropTypes.bool,
};
