import { useTranslation } from 'next-i18next';

import { Box, Button, ButtonGroup, MidText } from '@/base-components';

type Props = {
  methods: {
    handleAddItemToCart: () => void;
    handleContinue: () => void;
    handleCreateCart: () => void;
    handleRemoveItemFromCart: () => void;
  };
  state: {
    detailViewId: number;
    cartItems: any;
    cartId: number;
  };
};

export const SubmitSection = ({ methods, state }: Props) => {
  const { t } = useTranslation(['common', 'search']);

  const isInCart = (() => {
    const itemIds = (state.cartItems || []).map((i: any) => i?.id);
    return itemIds.includes(state.detailViewId);
  })();

  let submitSection = (
    <Box>
      <ButtonGroup mt={6} width="100%">
        <Button
          isFullWidth
          data-test-id="button-add-vehicle"
          onClick={methods.handleCreateCart}
          className="search-item-reserve-addmore"
          variant="outline"
        >
          {t('search:cart.addMore')}
        </Button>
        <Button
          isFullWidth
          data-test-id="button-continue"
          onClick={methods.handleContinue}
          className="search-item-reserve-submit"
        >
          {t('search:cart.continue')}
        </Button>
      </ButtonGroup>
      <MidText mt={4} textAlign="center">
        {t('search:cart.notYet')}
      </MidText>
    </Box>
  );

  if (state.cartId) {
    submitSection = (
      <Box>
        <Button onClick={methods.handleAddItemToCart}>
          {' '}
          {t('common:button.add')}
        </Button>
        <MidText mt={4} textAlign="center">
          {t('search:cart.notYet')}
        </MidText>
      </Box>
    );
  }

  if (isInCart) {
    submitSection = (
      <Box>
        <Button
          isFullWidth
          onClick={methods.handleRemoveItemFromCart}
          variant="outline"
        >
          {t('search:cart.remove')}
        </Button>
      </Box>
    );
  }
  return submitSection;
};
