export const updateCenter = (
  latitude,
  longitude,
  mapData,
  hasLargeCards = false
) => {
  const { bounds, center } = mapData;
  const {
    size: { height, width },
  } = mapData;
  const neLat = bounds.ne.lat;
  const neLng = bounds.ne.lng;
  const swLat = bounds.sw.lat;
  const swLng = bounds.sw.lng;
  const latPx = (neLat - swLat) / height;
  let newLat = center.lat;
  let newLng = center.lng;

  // keep below comments for reference for stacked pins implementation later
  // const latPxOffset =  latPx * ((104 * Math.min(3, itemMultiplier)) +
  // (11 * Math.min(2, itemMultiplier - 1)) + (2 + 20 + 15 + 53));
  const latPxOffset = hasLargeCards ? latPx * 430 : latPx * 440;

  const lngPx =
    neLng > swLng ? (neLng - swLng) / width : (swLng - neLng) / width;

  const lngPxOffset = lngPx * 120;

  if (latitude + latPxOffset > neLng) {
    newLat = (neLat + swLat) / 2 + latitude + latPxOffset - neLat;
  }

  if (longitude + lngPxOffset > neLng) {
    if (neLng > swLng) {
      newLng = (neLng + swLng) / 2 + longitude + lngPxOffset - neLng;
    } else {
      const avg = (neLng + swLng) / 2;
      const centerLng = avg > 0 ? 180 - avg : 180 + avg;
      newLng = centerLng + longitude + lngPxOffset - neLng;
    }
  } else if (longitude - lngPxOffset < swLng) {
    if (swLng < neLng) {
      newLng =
        (neLng + swLng) / 2 + longitude - lngPxOffset - swLng - lngPx * 20;
    } else {
      const avg = (neLng + swLng) / 2;

      const centerLng = avg > 0 ? 180 - avg : 180 + avg;
      newLng = centerLng - longitude - lngPxOffset + swLng;
    }
  }

  return { newLat, newLng };
};
