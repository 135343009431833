import { Button } from '@/base-components';
import { chakra } from '@/utils';

// SEARCH BUTTON
export const SearchAreaButton = chakra(Button, {
  baseStyle: {
    position: 'absolute',
    left: '50%',
    marginLeft: '-75px',
    top: { base: 3, lg: 4 },
    zIndex: 'mapOverlay',
  },
});
