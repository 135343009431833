import { Flex, Text } from '@fluidtruck/core';
import { Trans, useTranslation } from 'next-i18next';
import { useState } from 'react';

import { useResize } from '@/hooks';

import { ModalGroup } from '../modals';

interface MaintenanceProps {
  details: string;
  startAt: string;
  endAt: string;
  status: 'upcoming' | 'active';
}

export const MaintenanceBanner = ({
  details,
  startAt,
  endAt,
  status,
}: MaintenanceProps) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation(['common', 'maintenance']);
  const { isMobile, isIpad, isDesktop } = useResize();

  const modalHeaderSize = isMobile ? 18 : 22;
  const bannerTextSize = isMobile ? 16 : 18;

  const modalHeight = (isIpad || isDesktop) && 500;

  const handleClick = () => setOpen(prevState => !prevState);

  const isActive = status === 'active';

  return (
    <>
      <Flex
        style={{
          width: '100%',
          height: isMobile ? 76 : 50,
          cursor: 'pointer',
        }}
        backgroundColor="blue.700"
        alignItems="center"
        justifyContent="center"
        onClick={handleClick}
      >
        <Text
          mb={0}
          color="white"
          fontSize={bannerTextSize}
          fontWeight={700}
          pl={3}
          pr={3}
          textAlign="center"
        >
          {isActive ? (
            <Trans t={t} i18nKey="common:maintenance.currentOutage">
              There is a current maintenance outage until {{ endAt }}
            </Trans>
          ) : (
            <Trans t={t} i18nKey="common:maintenance.futureOutage">
              There will be a maintenance outage from {{ startAt }} to
              {{ endAt }}.
            </Trans>
          )}
        </Text>
      </Flex>
      <ModalGroup
        onClose={handleClick}
        size={isMobile ? 'full' : 'md'}
        bodyProps={{
          style: {
            minHeight: modalHeight,
          },
        }}
        headerProps={{
          style: {
            fontSize: modalHeaderSize,
          },
        }}
        isOpen={open}
        title={t('maintenance:outage.maintenanceWindowDetails')}
      >
        <Text align="center" pt={3}>
          {details}
        </Text>
      </ModalGroup>
    </>
  );
};
