import { GoogleMap, Marker } from '@react-google-maps/api';
import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';

import { useGoogleMapsApi } from '@/hooks';

interface Props {
  center: google.maps.LatLngLiteral;
  icon?: { url: string };
  zoom?: number;
}
const SimpleVehicleLocationMap = ({
  center,
  icon,
  zoom = 16,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const { isGoogleLoaded, googleMapsLoadError } = useGoogleMapsApi();

  if (googleMapsLoadError) {
    return <div>{t('messages.genericError')}</div>;
  }
  if (!isGoogleLoaded) {
    return <div>{t('messages.loadingText')}</div>;
  }
  return (
    <GoogleMap
      id="map-simple-view"
      mapContainerStyle={{ width: 'inherit', height: 'inherit' }}
      options={{
        disableDefaultUI: true,
        scrollwheel: false,
        clickableIcons: false,
        fullscreenControl: false,
        zoomControl: true,
      }}
      zoom={zoom}
      center={{ ...center }}
    >
      {icon && <Marker position={{ ...center }} icon={{ ...icon }} />}
    </GoogleMap>
  );
};

export { SimpleVehicleLocationMap };
