import { useDisclosure } from '@fluidtruck/core';
import moment from 'moment';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useSWR, { mutate } from 'swr';

import { ampli } from '@/amplitude';
import { FlexCol, FlexRow, ModalGroup, SubHeading } from '@/base-components';
import PromoCode from '@/components/PromoCode';
import { RequiredActionsCard } from '@/components/RequiredActionsCard';
import { useExperiment, useOrgDocuments } from '@/hooks';
import {
  useCart,
  useOrganizationContext,
  useOrgBilling,
  useResize,
  useUser,
} from '@/hooks';
import {
  FeedbackContext,
  ItemsContext,
  LoadingContext,
  useReservationEstimate,
} from '@/lib/context';
import { heights } from '@/theme';
import {
  centsToDollars,
  formatMoney,
  withCurrentTimezone,
} from '@/utils/conversions';
import { get } from '@/utils/helpers';
import { currentLocation } from '@/utils/location';

import {
  Deposit,
  Estimate,
  PremiumMaintenance,
  SalesTaxLineItem,
  ServiceFee,
  UserCredit,
} from '../../LineItemStyles';
import {
  creditAmount,
  getTaxLineItems,
  getTotalTaxAmount,
  lineItemAmount,
  totalAmount,
} from '../helpers';
import { SummaryDurationSearch } from '../SummaryDurationSearch';
import SummaryLogin from '../SummaryLogin';
import {
  DividerContainer,
  MileageContainer,
  SmallText,
  StyledDivider,
} from './styles';
import { SubmitSection } from './SubmitSection';

const { topNavMobile } = heights;

export const SearchSummary = () => {
  const router = useRouter();
  const { t } = useTranslation(['common', 'search']);

  // state
  const {
    isOpen: loginOpen,
    onOpen: setLoginOpen,
    onClose: closeLogin,
  } = useDisclosure();
  const [loc, setLoc] = useState({});

  // hooks
  const { user } = useUser();
  const { isMobile } = useResize();

  // context
  const {
    context: { org },
  } = useOrganizationContext();
  const { organizations } = useOrgBilling();
  const { cart, addItemsToCart, createCart, removeItemFromCart } = useCart();

  const { showFeedback } = useContext(FeedbackContext);
  const { updateLoading } = useContext(LoadingContext);
  const itemsContext = useContext(ItemsContext);
  const { detailView, searchInfo, updateDetailView, updateSelectedItem } =
    itemsContext;
  const { org: { documents } = {} } = useOrgDocuments();

  const detailViewId = detailView?.detailViewId;

  // constants
  const authenticated = user?.email != null;
  const stepOneComplete = user?.first != null && user?.last != null;
  const stepTwoComplete = user?.['phone-number-verified'];
  const stepThreeComplete =
    user?.organizations.reduce((bm, o) => bm.concat(o?.['billing-methods']), [])
      .length > 0;

  const internalOrgType =
    org?.organization?.['organization-settings']['internal-type'];
  const orgId = org?.id;

  const requiredActionsArray = () => {
    const arr = [];
    if (!documents?.some(doc => doc['type'] === 'insurance')) {
      arr.push({
        action: t('search:insuranceAlert.uploadCertificateInsurance'),
        link: `/account/organization/${orgId}`,
      });
    }
    if (user?.identification?.status !== 'verified') {
      arr.push({
        action: t('search:insuranceAlert.uploadLicense'),
        link: '/account',
      });
    }
    return arr;
  };

  const { reservationEstimate, updateEstimate, errorMessage } =
    useReservationEstimate();

  const methods = useForm({
    defaultValues: {
      'pick-up': moment(searchInfo?.start).toISOString(),
      'drop-off': moment(searchInfo?.end).toISOString(),
      'promo-code': '',
    },
  });

  const { watch, getValues } = methods;
  const promoCode = watch('promo-code');

  const { data: itemData } = useSWR(
    detailViewId ? `api/publicitems/${detailViewId}` : null,
    get
  );

  const loadLocation = async () => {
    const res = await currentLocation();
    setLoc(res);
  };

  const updatePromo = async () => {
    const formValues = getValues();
    updateEstimate({
      'promo-code': formValues?.['promo-code'].toLocaleUpperCase(),
    });
  };

  const handleContinue = () => {
    ampli.continueToBook();
    if (!authenticated) {
      router.push('/account/signup?booking=true');
    } else if (authenticated && !stepOneComplete) {
      router.push('/account/create?booking=true');
    } else if (authenticated && !stepTwoComplete) {
      router.push('/account/create/phone?booking=true');
    } else if (authenticated && !stepThreeComplete) {
      router.push('/account/create/billing_method?booking=true');
    } else if (authenticated) {
      router.push('/book');
      ampli.viewBookingReviewPage({
        userId: user?.id,
        orgId: org?.organization?.id,
        hasBookingCart: false,
        reservationEndTime: reservationEstimate?.['drop-off'],
        reservationStartTime: reservationEstimate?.['pick-up'],
        itemIds: [reservationEstimate?.item?.id], // only multiple items in array during cart flow
        promoCode: promoCode || '',
        deviceType: isMobile ? 'web-mobile' : 'web-desktop',
      });
    }
  };

  const handleAddItemToCart = async () => {
    updateLoading(true);
    const pickUp = moment(searchInfo?.start).toISOString();
    const dropOff = moment(searchInfo?.end).toISOString();
    const response = await addItemsToCart({
      itemIds: [detailViewId],
      pickUp,
      dropOff,
    });
    updateLoading(false);
    if (response.status === 'success') {
      updateDetailView({ type: '', detailViewId: null });
    }
  };

  const handleRemoveItemFromCart = async () => {
    updateLoading(true);
    await removeItemFromCart(detailViewId);
    updateLoading(false);
  };

  const handleCreateCart = useCallback(async () => {
    ampli.addVehicleAndBookMore();
    if (!user) {
      setLoginOpen();
      return;
    }

    const defaultOrgId = user['default-organization-id'];
    const defaultOrgProfile = user.organizations.find(
      ({ organization }) => organization.id === defaultOrgId
    );

    const defaultOrg = defaultOrgProfile.organization;
    const defaultBorrowLimit = defaultOrg['borrow-limit'];

    const fallbackOrgProfile = user.organizations.find(
      ({ organization, profile }) =>
        organization['borrow-limit'] > 1 && profile.role !== 'driver'
    );
    const fallbackOrg = fallbackOrgProfile?.organization;

    const bookingOrg = defaultBorrowLimit > 1 ? defaultOrg : fallbackOrg;

    if (bookingOrg) {
      updateLoading(true);

      const formValues = getValues();
      const pickUp = searchInfo.start.toISOString();
      const dropOff = searchInfo.end.toISOString();
      const billingId = bookingOrg['default-billing-method-id'];
      const organizationId = bookingOrg.id;

      const response = await createCart({
        billingId,
        dropOff,
        itemId: detailViewId,
        location: loc,
        organizationId,
        pickUp,
        promoCode: formValues['promo-code'],
        loc,
        vehicleType: searchInfo.type,
      });

      updateLoading(false);

      try {
        if (response?.status !== 'success') throw new Error();
        updateSelectedItem(0);
        updateEstimate({ 'item-id': 0 });
        updateDetailView({ type: '', detailViewId: null });
      } catch (e) {
        const serverMessage =
          response?.messages?.[response?.messages?.length - 1];

        let message = serverMessage;
        if (serverMessage === 'item must be instabook to add to cart') {
          message = t('search:cart.error.insta');
        } else if (
          serverMessage ===
          'adding this item will surpass your vehicle borrow limit'
        ) {
          message = t('search:cart.error.limit');
        }
        showFeedback(message, 'error');
      }
    }
  }, [
    user,
    setLoginOpen,
    updateLoading,
    getValues,
    searchInfo.start,
    searchInfo.end,
    searchInfo.type,
    createCart,
    detailViewId,
    loc,
    updateSelectedItem,
    updateEstimate,
    updateDetailView,
    showFeedback,
    t,
  ]);

  const submitSectionProps = {
    methods: {
      handleAddItemToCart,
      handleContinue,
      handleCreateCart,
      handleRemoveItemFromCart,
    },
    state: {
      detailViewId,
      cartItems: cart?.items,
      cartId: cart?.['cart-id'],
    },
  };

  const handleLoginCallback = async () => {
    await handleCreateCart();
  };

  useEffect(() => {
    loadLocation();
  }, []);

  useEffect(() => {
    if (loginOpen) {
      const url = 'api/v1/organizations/profiles/billing_methods';
      mutate(url, async () => {
        const info = await get(url, user);
        return info;
      });

      closeLogin();
      handleCreateCart();
    }
  }, [user, loginOpen, closeLogin, handleCreateCart]);

  useEffect(() => {
    if (loginOpen && organizations?.length > 0) {
      closeLogin();
      handleCreateCart();
    }
  }, [organizations, loginOpen, closeLogin, handleCreateCart]);

  const { 'invoice-payments': invoicePayments } =
    reservationEstimate?.invoice || {};

  const taxLineItems = invoicePayments
    ? getTaxLineItems(invoicePayments)
    : null;

  const totalTax = getTotalTaxAmount(invoicePayments);

  const requiredActions = requiredActionsArray();

  const { getVariant } = useExperiment();
  const coiExperiment = getVariant('fluid-7389-coi-warning');
  const coiFlagActive = coiExperiment?.value === 'show';

  const showRequiredActionsCard =
    requiredActions.length > 0 &&
    internalOrgType !== 'Fedex Express' &&
    internalOrgType !== 'UPS' &&
    coiFlagActive;

  return (
    <FormProvider {...methods}>
      <FlexCol data-test-id="search-summary-container" width="100%" p={5}>
        <FlexCol
          data-test-id="search-summary-container-inner"
          mb={{ base: `${topNavMobile}px`, lg: 0 }}
        >
          <SummaryDurationSearch
            pickUp={withCurrentTimezone(searchInfo?.start)}
            dropOff={withCurrentTimezone(searchInfo?.end)}
          />
          <MileageContainer>
            {itemData?.data?.details?.unlimited_miles ? (
              <SmallText size={16}>{t('common:unlimitedMileage')}</SmallText>
            ) : (
              <>
                <SmallText size={16}>
                  {t('search:includedMilage', {
                    mileage:
                      reservationEstimate?.item?.details?.mileage_limit_daily,
                  })}
                </SmallText>
                <DividerContainer>
                  <StyledDivider
                    orientation="vertical"
                    style={{ height: 17 }}
                  />
                </DividerContainer>
                <SmallText size={16}>
                  {formatMoney(
                    centsToDollars(
                      reservationEstimate?.item?.details?.cost_per_mile
                    )
                  )}{' '}
                  {t('search:perAdditionalMile')}
                </SmallText>
              </>
            )}
          </MileageContainer>
          <Estimate amount={lineItemAmount(reservationEstimate, 'estimate')} />
          <PremiumMaintenance
            amount={lineItemAmount(reservationEstimate, 'premium_maintenance')}
          />
          <ServiceFee
            amount={lineItemAmount(reservationEstimate, 'booking_fee')}
          />
          <Deposit amount={lineItemAmount(reservationEstimate, 'deposit')} />

          {!!totalTax && (
            <SalesTaxLineItem amount={totalTax} lineItems={taxLineItems} />
          )}
          <UserCredit amount={creditAmount(reservationEstimate)} />
          <PromoCode
            amount={lineItemAmount(reservationEstimate, 'promo')}
            error={errorMessage}
            onClick={updatePromo}
          />
          <FlexRow justify="space-between" align="center" mt={6}>
            <SubHeading>{t('total')}</SubHeading>
            <SubHeading>{totalAmount(reservationEstimate)}</SubHeading>
          </FlexRow>

          {showRequiredActionsCard && (
            <FlexRow mt={5}>
              <RequiredActionsCard requiredActions={requiredActions} />
            </FlexRow>
          )}

          <SubmitSection {...submitSectionProps} />
        </FlexCol>
      </FlexCol>

      <ModalGroup
        isOpen={loginOpen}
        title={t('login.logIn')}
        onClose={closeLogin}
      >
        <SummaryLogin onFinish={handleLoginCallback} />
      </ModalGroup>
    </FormProvider>
  );
};

export default SearchSummary;
