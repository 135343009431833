import styled from '@emotion/styled';
import { Avatar, VStack } from '@fluidtruck/core';
import { Card } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';

import { Header, LinkButton } from '@/base-components';
import Link from '@/base-components/Link';

const CardContainer = styled.div({
  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  width: 184,
  position: 'absolute',
  right: 0,
  bottom: 65,
  boxSizing: 'border-box',
  transform: 'translateX(50%)',
  zIndex: 1,
});

const StyledCard = styled(Card)({
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
});

const CardContent = styled.div({
  padding: 9,
});

const StyledHeader = styled(Header)({
  lineHeight: '105%',
  fontSize: 16,
});

const StyledSubheader = styled(Header)({
  marginTop: 3,
});

const ManagerCard = ({
  thumbnail,
  title,
  fleetNumber,
  id,
  handleContactSupport,
  isDropOffPastNow,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { orgId } = router.query;

  return (
    <>
      <CardContainer>
        <StyledCard>
          <Avatar
            src={thumbnail}
            width="100%"
            height="140px"
            borderRadius="none"
          />
          <CardContent>
            <StyledHeader size={18}>{title}</StyledHeader>
            <StyledSubheader size={16}>{fleetNumber}</StyledSubheader>
            <VStack sx={{ margin: '4px 0' }}>
              <Link
                href="/reservations/[orgId]/[id]/details"
                as={`/reservations/${orgId}/${id}/details`}
              >
                {t('button.viewRes')}
              </Link>
              {!isDropOffPastNow && (
                <Link
                  href="/reservations/[orgId]/[id]/details?extend=true"
                  as={`/reservations/${orgId}/${id}/details?extend=true`}
                >
                  {t('button.extend')}
                </Link>
              )}
              <Link href="/messages">{t('button.sendMsg')}</Link>
              <LinkButton
                onClick={handleContactSupport}
                style={{ marginTop: 5 }}
              >
                {t('button.help')}
              </LinkButton>
            </VStack>
          </CardContent>
        </StyledCard>
      </CardContainer>
    </>
  );
};

export default ManagerCard;

ManagerCard.propTypes = {
  thumbnail: PropTypes.string,
  title: PropTypes.string,
  fleetNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.number,
  handleContactSupport: PropTypes.func,
  isDropOffPastNow: PropTypes.bool.isRequired,
};
