export const vehicleLocationMarker = (
  isAvailable?: boolean
) => `<svg width="42" height="57" viewBox="0 0 42 57" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#ItemLocation_svg__filter0_d)">
<mask id="ItemLocation_svg__a" fill="#fff">
<path fill-rule="evenodd" clip-rule="evenodd" d="M35.142 26.33A16.803 16.803 0 0038 16.93C38 7.581 30.389 0 21 0S4 7.58 4 16.931c0 3.477 1.053 6.71 2.858 9.399h-.003l13.047 19.094a1.33 1.33 0 002.196 0L35.144 26.33h-.002z"></path>
</mask>
<path fill-rule="evenodd" clip-rule="evenodd" d="M35.142 26.33A16.803 16.803 0 0038 16.93C38 7.581 30.389 0 21 0S4 7.58 4 16.931c0 3.477 1.053 6.71 2.858 9.399h-.003l13.047 19.094a1.33 1.33 0 002.196 0L35.144 26.33h-.002z" fill="${
  isAvailable ? '#00B2E3' : '#C9CDCF'
}"></path>
<path d="M35.142 26.33l-1.66-1.115-2.091 3.115h3.751v-2zm-28.284 0v2h3.751l-2.09-3.115-1.661 1.115zm-.003 0v-2H3.066l2.138 3.128 1.651-1.128zm13.047 19.094l-1.652 1.128 1.652-1.128zm2.196 0l1.651 1.128-1.651-1.128zM35.144 26.33l1.652 1.128 2.137-3.128h-3.789v2zM36 16.93c0 3.068-.927 5.914-2.518 8.284l3.32 2.23A18.804 18.804 0 0040 16.93h-4zM21 2c8.292 0 15 6.693 15 14.931h4C40 6.468 31.486-2 21-2v4zM6 16.931C6 8.693 12.708 2 21 2v-4C10.514-2 2 6.468 2 16.931h4zm2.518 8.284A14.804 14.804 0 016 16.931H2c0 3.887 1.178 7.506 3.197 10.514l3.321-2.23zM6.855 28.33h.003v-4h-.003v4zm14.698 15.965L8.507 25.201l-3.303 2.257L18.25 46.552l3.303-2.257zm-1.106 0a.67.67 0 011.106 0l-3.303 2.257c1.323 1.935 4.177 1.935 5.5 0l-3.303-2.257zm13.046-19.094L20.447 44.296l3.302 2.257 13.047-19.094-3.303-2.256zm1.65 3.129h.001v-4h-.002v4z" fill="#fff" mask="url(#ItemLocation_svg__a)"></path>
<circle cx="21" cy="17" r="7" fill="#fff"></circle>
</g>
<defs>
<filter id="ItemLocation_svg__filter0_d" x="0" y="0" width="42" height="56.003" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
<feOffset dy="6"></feOffset>
<feGaussianBlur stdDeviation="2"></feGaussianBlur>
<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>
<feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
<feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
</filter>
</defs>
</svg>`;

export const VehicleLocationMarkerBtoa = Buffer.from(
  vehicleLocationMarker(true)
).toString('base64');
