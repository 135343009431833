import styled from '@emotion/styled';
import { Divider } from '@material-ui/core';

import { SubHeader } from '@/base-components';
import { generateMQ } from '@/utils/helpers';

const mqMax = generateMQ('max');

export const SmallText = styled(SubHeader)(props => ({
  color: props?.theme?.colors?.gray[900],
  display: 'flex',
  alignItems: 'flex-end',
  [mqMax[0]]: {
    fontSize: 14,
  },
}));

export const DividerContainer = styled.div({
  display: 'flex',
  alignItems: 'flex-end',
  marginBottom: 3,
});

export const MileageContainer = styled.div({
  display: 'flex',
  paddingTop: 11,
});

export const StyledDivider = styled(Divider)(props => ({
  backgroundColor: props?.theme?.colors?.gray[700],
  margin: '0 8px',
}));
