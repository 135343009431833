import { CargoVanIcon } from '@fluidtruck/icons';
import { Popover, Toolbar } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { ampli } from '@/amplitude';
import { Box, Button, CloseButton, FlexCol, FlexRow } from '@/base-components';
import ItemCartCard from '@/components/ItemCart/ItemCartCard';
import {
  ButtonContainer,
  CartBadge,
  CartBar,
  CartMenuBar,
  CartPopover,
  CartToolBar,
  EstimateAmount,
  EstimateContainer,
  EstimateText,
  ItemListContainer,
} from '@/components/ItemCart/itemCartStyles';
import { useCart, useOrganizationContext, useResize, useUser } from '@/hooks';
import { useReservationEstimate } from '@/lib/context';
import { centsToDollars, formatMoney } from '@/utils/conversions';

export const ItemCart = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { t } = useTranslation('search');

  const { isMobile } = useResize();
  const router = useRouter();

  const { cart, updateCartOrg } = useCart();
  const { context } = useOrganizationContext();
  const { user } = useUser();
  const { reservationEstimate } = useReservationEstimate();

  const itemIds = cart?.items?.map(i => i.id);

  const showCart = e => {
    setAnchorEl(e.currentTarget);
  };

  const hideCart = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleClick = () => {
    router.push('/book_cart');

    ampli.viewBookingReviewPage({
      userId: user?.id,
      orgId: context?.org?.organization?.id,
      hasBookingCart: true,
      reservationEndTime: reservationEstimate?.['drop-off'],
      reservationStartTime: reservationEstimate?.['pick-up'],
      itemIds: [itemIds], // only multiple items in array during cart flow
      promoCode: reservationEstimate?.['promo-code'],
      deviceType: isMobile ? 'web-mobile' : 'web-desktop',
    });
  };

  useEffect(() => {
    // Adding updateCartOrg to deps causes dozens of re-renders.
    if (user) {
      updateCartOrg({
        orgId: context.org?.organization?.id,
        scheduled:
          context.org?.organization?.achEnabled ||
          context.org?.organization?.['organization-settings']?.[
            'credit-card-payg-enabled'
          ],
      });
    }
  }, [context.org?.organization?.id, user]);

  return (
    <CartBar>
      <CartToolBar isMobile={isMobile}>
        <FlexCol align="center" h="88px" justify="flex-end" w="100%">
          <FlexRow align="center" justify="space-between">
            <CartBadge
              badgeContent={cart?.items?.length}
              style={{ cursor: 'pointer', marginLeft: '48px' }}
              onClick={showCart}
            >
              <CargoVanIcon color="blue.500" w="48px" h="26px" />
            </CartBadge>

            <FlexRow justify="flex-end" align="center" mr="48px">
              <EstimateContainer>
                <EstimateText>{t('estTotal')}</EstimateText>
                <EstimateAmount>
                  {formatMoney(
                    centsToDollars(cart?.['payment-summary']?.total)
                  )}
                </EstimateAmount>
              </EstimateContainer>
              <ButtonContainer>
                <Button
                  isFullWidth
                  data-test-id="btn-cart-book"
                  size="lg"
                  onClick={handleClick}
                >
                  {t('cart.book')}
                </Button>
              </ButtonContainer>
            </FlexRow>
          </FlexRow>

          <Box align="center" bg="blue.500" color="white" h={6} mt={2} w="100%">
            {context.org?.organization
              ? t('cart.limit', {
                  name: context.org?.organization?.name,
                  maxVehicles: context?.org?.organization?.['borrow-limit'],
                })
              : ''}
          </Box>
        </FlexCol>

        <Popover
          marginThreshold={0}
          open={open}
          PaperProps={{
            style: {
              borderRadius: '10px 10px 0px 0px',
              maxWidth: 'unset',
              ...(isMobile ? { width: '100%' } : {}),
            },
          }}
          style={{
            zIndex: 20,
            borderRadius: '10px 10px 0px 0px',
          }}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={hideCart}
        >
          <CartPopover>
            <CartMenuBar>
              <Toolbar style={{ height: 50, minHeight: 50, paddingRight: 20 }}>
                <CloseButton onClick={hideCart} />
              </Toolbar>
            </CartMenuBar>
            <ItemListContainer>
              {cart?.items?.map((item, idx, items) => (
                <ItemCartCard
                  key={item?.id}
                  pickUp={cart?.['pick-up']}
                  dropOff={cart?.['drop-off']}
                  item={item}
                  items={items}
                />
              ))}
            </ItemListContainer>
          </CartPopover>
        </Popover>
      </CartToolBar>
    </CartBar>
  );
};

export default ItemCart;
