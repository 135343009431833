import { chakra } from '@fluidtruck/core';
import { useState } from 'react';

import { ForgotPassword } from '@/components/ForgotPassword';
import { Login } from '@/components/LoginSignup';

const SummaryLoginContainer = chakra('div', {
  baseStyle: {
    marginTop: -5,
    height: { base: '100%', lg: '88%' },
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  label: 'SummaryLoginContainer',
});

const ForgotPasswordContainer = chakra('div', {
  baseStyle: {
    height: { base: '100%', lg: 'calc(100% - 40px)' },
  },
  label: 'ForgotPasswordContainer',
});

const SummaryLogin = ({ onFinish }: { onFinish: () => void }) => {
  const [forgotPassword, setForgotPassword] = useState(false);

  if (forgotPassword) {
    return (
      <ForgotPasswordContainer>
        <ForgotPassword setForgotPassword={setForgotPassword} />
      </ForgotPasswordContainer>
    );
  }

  return (
    <SummaryLoginContainer>
      <Login
        blockOnSubmit
        setForgotPassword={setForgotPassword}
        onFinish={onFinish}
      />
    </SummaryLoginContainer>
  );
};

export default SummaryLogin;
