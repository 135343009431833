import { usePrevious } from '@fluidtruck/core';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';

import { Box, FlexRow, SpinnerContainer } from '@/base-components';
import { MultiVehicleMap } from '@/components/MultiVehicleMap';
import { useGoogleMaps, useGoogleMapsApi } from '@/hooks';
import { ItemsContext } from '@/lib/context';
import { ACTIONS } from '@/lib/context/GoogleMaps/reducer/map_actions';

import { updateCenter } from './helpers';
import ItemPin from './ItemPin';
import { SearchAreaButton } from './styles';

const ItemMap = ({ isLoading }) => {
  const { t } = useTranslation('search');
  const { isGoogleLoaded } = useGoogleMapsApi();
  const {
    state: { zoom, latLng, mapInstance, isMapLoaded },
    dispatch,
    setMapLatLng,
  } = useGoogleMaps();

  const {
    items = [],
    updateMapLocation,
    mapLocation,
    updateSelectedItem,
    selectedItem,
    userLocation,
  } = useContext(ItemsContext);

  const prevSelectedItem = usePrevious(selectedItem);

  const [searchButtonVisible, setSearchButtonVisible] = useState(false);
  const [allowZoom, setAllowZoom] = useState(true);

  const selectedPin = items.find(item => item.item.id === selectedItem);

  useEffect(
    function updatedSelectedItemView() {
      if (prevSelectedItem === selectedItem || !selectedItem) return;
      const getBounds = mapInstance?.getBounds();

      const mapData = {
        bounds: {
          ne: {
            lat: getBounds.getNorthEast().lat(),
            lng: getBounds.getNorthEast().lng(),
          },
          sw: {
            lat: getBounds.getSouthWest().lat(),
            lng: getBounds.getSouthWest().lng(),
          },
        },
        center: {
          lat: getBounds.getCenter().lat(),
          lng: getBounds.getCenter().lng(),
        },
        size: {
          width: mapInstance.getDiv().offsetWidth,
          height: mapInstance.getDiv().offsetHeight,
        },
        zoom,
      };

      const { newLat, newLng } = updateCenter(
        selectedPin.coordinates.lat,
        selectedPin.coordinates.lng,
        mapData
      );

      dispatch({
        type: ACTIONS.SET_LATLNG,
        payload: { lat: newLat, lng: newLng },
      });
    },
    [
      dispatch,
      mapInstance,
      prevSelectedItem,
      selectedItem,
      selectedPin?.coordinates.lat,
      selectedPin?.coordinates.lng,
      zoom,
    ]
  );

  const handleSearchButton = () => {
    setSearchButtonVisible(false);
    const center = mapInstance.getBounds().getCenter();
    const mapLat = center.lat();
    const mapLng = center.lng();
    updateMapLocation({ ...mapLocation, lat: mapLat, lng: mapLng });
  };

  if (!latLng) {
    return null;
  }

  const handleDragEnd = () => {
    if (!mapInstance) return;
    setMapLatLng();
    setSearchButtonVisible(true);
  };

  const handleDrag = () => {
    if (selectedItem) updateSelectedItem();
  };

  const handleZoom = () => {
    const isNotUserZoomClick = !isMapLoaded || !zoom;
    if (isNotUserZoomClick) return;
    setSearchButtonVisible(true);
  };

  const handlePinClick = id => {
    updateSelectedItem(id);
    setAllowZoom(false);
  };

  const handlePinCleanup = () => {
    updateSelectedItem(0);
    setAllowZoom(true);
  };

  return (
    <>
      <style jsx global>
        {`
          .gm-style-pbc + div {
            box-shadow: inset 4px 4px 4px -4px rgba(0, 0, 0, 0.4),
              inset 0 -4px 4px -4px rgba(0, 0, 0, 0.4);
          }
        `}
      </style>
      <Box h="100%" position="relative" w="100%">
        {searchButtonVisible && !selectedItem && !isLoading && (
          <SearchAreaButton
            data-test-id="button-search-area"
            onClick={handleSearchButton}
          >
            {t('searchArea')}
          </SearchAreaButton>
        )}

        {isLoading && (
          <FlexRow alignContent="center" justifyContent="center" height="100%">
            <SpinnerContainer />
          </FlexRow>
        )}

        {isGoogleLoaded && (
          <MultiVehicleMap
            userLocation={userLocation}
            allowZoom={allowZoom}
            handleZoom={handleZoom}
            selectedItem={selectedItem}
            handlePinCleanup={handlePinCleanup}
            infoBox={
              selectedPin && {
                position: selectedPin.coordinates,
                BoxContent: (
                  <ItemPin
                    id={selectedPin.item.id}
                    distance={selectedPin['distance-miles']}
                    cost={selectedPin.cost}
                    title={selectedPin.item.title}
                    thumbnail={
                      selectedPin.item.pictures?.[0]?.thumb ||
                      selectedPin.item.pictures?.[0].standard
                    }
                    isElectric={selectedPin.item['is-electric-vehicle']}
                    fleetNumber={selectedPin.item['fleet-number']}
                    instabook={selectedPin.item.instabook}
                    isAvailable={selectedPin.item.isAvailable}
                    onClick={handlePinClick}
                  />
                ),
              }
            }
            items={items}
            handlePinClick={handlePinClick}
            onDragEnd={handleDragEnd}
            onDrag={handleDrag}
          />
        )}
      </Box>
    </>
  );
};

export default ItemMap;

ItemMap.propTypes = {
  isLoading: PropTypes.bool,
};
