import { CircleIcon, InfoIcon, InstantBookIcon } from '@fluidtruck/icons';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, {
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ampli } from '@/amplitude';
import { Button } from '@/base-components';
import { Tooltip } from '@/base-components';
import { useCart, useOrganizationContext, useResize, useUser } from '@/hooks';
import { ItemsContext, useReservationEstimate } from '@/lib/context';
import { Item } from '@/types';
import { chakra } from '@/utils';

import * as Styles from './ItemCard.styles';

const convertToFeetAndInches = (num: number) => {
  const feet = Math.floor(num);
  const inches = (num - feet) * 12;

  return `${feet}' ${Math.round(inches)}"`;
};

interface Props {
  itemId: number;
}

const ItemCard = ({ itemId }: Props) => {
  const { t } = useTranslation(['common', 'ev', 'search']);
  const { user } = useUser();
  const { isDesktop, isMobile } = useResize();
  const { cart, addItemsToCart, removeItemFromCart } = useCart();
  const router = useRouter();

  const [loading, setLoading] = useState(false);

  const {
    context: {
      org: { organization },
    },
  } = useOrganizationContext();

  const {
    items,
    detailView: { detailViewId },
    selectedItem,
    updateDetailView,
    updateSelectedItem,
  } = useContext(ItemsContext);

  const { updateEstimate } = useReservationEstimate();

  const item = useMemo(() => {
    const currentItem = items?.find(i => i.item.id === itemId);
    if (!currentItem) {
      return null;
    }

    const { measurements, cost = 0 } = currentItem;
    const cargoHeight = measurements?.['cargo-height'] || 0;
    const cargoLength = measurements?.['cargo-length'] || 0;
    const cargoWidth = measurements?.['cargo-width'] || 0;

    const hasValidDimensions =
      cargoHeight !== 0 && cargoLength !== 0 && cargoWidth !== 0;

    let cubic = 0;
    if (hasValidDimensions) {
      cubic = cargoHeight / 12;
      cubic *= cargoLength / 12;
      cubic *= cargoWidth / 12;
    }

    return {
      ...currentItem.item,
      distance: currentItem?.['distance-miles'].toFixed(2),
      cargoHeight: convertToFeetAndInches(cargoHeight),
      cargoLength: convertToFeetAndInches(cargoLength),
      cargoWidth: convertToFeetAndInches(cargoWidth),
      cost: Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(cost / 100),
      cubic: cubic.toFixed(2),
      hasValidDimensions,
    };
  }, [itemId]);

  const inCart = useMemo(() => {
    if (!cart) {
      return false;
    }

    return cart?.items?.find((i: Item) => i.id === item?.id) !== undefined;
  }, [cart]);

  useEffect(() => {
    if (isMobile && selectedItem) {
      updateSelectedItem(0);
    }
  }, [isMobile, selectedItem]);

  if (!item) return <></>;

  const handleSelect = (e: SyntheticEvent) => {
    const { innerText } = e.target as HTMLElement;

    if (
      organization &&
      organization?.['borrow-limit'] <= 1 &&
      innerText === t('common:button.reserve')
    ) {
      router.push('/book');
      updateEstimate({ 'item-id': item.id });
      return;
    }

    if (detailViewId !== item.id) {
      updateDetailView({
        type: '',
        detailViewId: null,
      });
    }

    if (
      [
        t('common:button.cancel'),
        t('common:button.remove'),
        t('common:button.add'),
      ].includes(innerText)
    ) {
      return;
    }

    if (isDesktop) {
      updateSelectedItem(item.id);
    }

    if (innerText === t('common:button.reserve')) {
      updateEstimate({ 'item-id': item.id });
      ampli.vehicleDetailsReserve({
        userId: user?.id,
      });
      updateDetailView({
        type: 'reservation',
        detailViewId: item.id,
        isAvailable: item.isAvailable,
      });
    } else if (isMobile) {
      ampli.viewVehicleDetails({
        userId: user?.id,
      });
      updateDetailView({
        type: 'item',
        detailViewId: item.id,
        isAvailable: item.isAvailable,
      });
    } else {
      ampli.viewVehicleOnMap({
        userId: user?.id,
      });
    }
  };

  const handleAddToCart = async () => {
    setLoading(true);

    const pickUp = cart?.['pick-up'];
    const dropOff = cart?.['drop-off'];

    const response = await addItemsToCart({
      itemIds: [item.id],
      pickUp,
      dropOff,
    });

    setLoading(false);

    if (response.status === 'success') {
      updateDetailView({
        type: '',
        detailViewId: null,
      });
    }
  };

  const handleRemoveFromCart = async () => {
    setLoading(true);
    await removeItemFromCart(item.id);
    setLoading(false);
  };

  const image =
    item?.pictures?.[0]?.thumb ||
    item?.pictures?.[0]?.standard ||
    '/img/cargo-van-square.png';

  const id = item.instabook
    ? `item-reserve-instabook-${item.id}`
    : `item-reserve-${item.id}`;

  let action = (
    <Button
      data-test-id={id}
      isFullWidth={isMobile}
      isDisabled={!item.isAvailable}
    >
      {t('common:button.reserve')}
    </Button>
  );

  if (cart) {
    if (inCart) {
      action = (
        <Button
          data-test-id={`item-reserve-${itemId}`}
          isDisabled={loading}
          isFullWidth={isMobile}
          isLoading={loading}
          variant="outline"
          onClick={handleRemoveFromCart}
        >
          {cart.items.length > 1
            ? t('common:button.remove')
            : t('common:button.cancel')}
        </Button>
      );
    } else {
      action = (
        <Button
          data-test-id={`item-add-${itemId}`}
          isDisabled={loading || !item.isAvailable}
          isFullWidth={isMobile}
          isLoading={loading}
          onClick={handleAddToCart}
        >
          {t('common:button.add')}
        </Button>
      );
    }
  }

  return (
    <Styles.Container
      data-test-id={`item-card-${itemId}`}
      onClick={handleSelect}
    >
      <Styles.Image>
        {image && (
          <Image
            alt={item.title}
            height={768}
            layout="responsive"
            quality={100}
            src={image}
            width={1024}
            objectFit="cover"
          />
        )}
      </Styles.Image>
      <Styles.Title>
        {item.title}
        {item.hasValidDimensions && (
          <chakra.span ml="2px">
            <Tooltip
              label={
                <>
                  {[
                    `${item.cargoLength} L`,
                    `${item.cargoWidth} W`,
                    `${item.cargoHeight} H`,
                  ].join(' x ')}
                </>
              }
              placement="right"
            >
              <InfoIcon
                verticalAlign="top"
                color="fluidBlue"
                h="24px"
                w="14px"
              />
            </Tooltip>
          </chakra.span>
        )}
      </Styles.Title>
      <Styles.Distance>{item.distance} mi.</Styles.Distance>
      <Styles.Features>
        {item.instabook && (
          <Styles.Feature>
            <span>{t('common:instantBook')}</span>
            <InstantBookIcon color="blue.500" />
          </Styles.Feature>
        )}
        {item['is-electric-vehicle'] && (
          <Styles.Feature>
            <span>{t('ev:ev')}</span>
            <CircleIcon color="green.500" />
          </Styles.Feature>
        )}
      </Styles.Features>
      <Styles.Total>
        <div>{t('search:estTotal')}</div>
        {item.cost}
      </Styles.Total>
      <Styles.Action>{action}</Styles.Action>
    </Styles.Container>
  );
};

export default ItemCard;
