import { Moment } from 'moment';

export type GeoCodeProps = {
  lat?: number;
  lng?: number;
  address?: string;
};

export type HandleUrlProps = {
  searchOptions: SearchInfo;
  mapLocationOptions: Location;
  filterOptions: ApplyInfo;
};

export type ViewInfo = {
  detailViewId: number | null;
  type: string;
  isAvailable?: boolean;
};

export type ApplyInfo = {
  isInstantBook?: boolean;
  showEV?: boolean;
};

export type Location = {
  lat?: number;
  lng?: number;
  address?: string;
};

export type SearchInfo = {
  start: Moment;
  end: Moment;
  type: string;
};

export type SortInfo = {
  sortField?: string;
  sortDirection?: SortDirection;
};

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export type QueryParams = {
  type?: string;
  start?: string;
  end?: string;
  'place-id'?: string;
  location?: string;
  geocode?: string;
  'fleet-numbers': string;
  'is-electric-vehicle': string;
  instabook: string;
  isMobileAndSpecificSearchOpen: string;
};

export type MapLocationInfo = {
  lat?: number;
  lng?: number;
  address?: string;
};
