import { Divider, Flex, Text } from '@fluidtruck/core';

import { chakra } from '@/utils';

export const DateTimeContainer = chakra('div', {
  baseStyle: {
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: '1fr 48px',
    mt: 6,
  },
  label: 'DateTimeContainer',
});

export const Features = chakra(Flex, {
  baseStyle: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& img:last-of-type': {
      marginLeft: 8,
    },
  },
  label: 'Features',
});

export const Row = chakra(Flex, {
  baseStyle: {
    alignItems: 'center',
  },
  label: 'Row',
});

export const DateContainer = chakra(Text, {
  baseStyle: {
    lineHeight: '8',
    fontWeight: 'bold',
    width: 'auto',
  },
  label: 'DateContainer',
});

export const Image = chakra('img', {
  baseStyle: {
    width: '100%',
    overflow: 'hidden',
    display: 'block',
    maxWidth: 20,
    borderRadius: 'base',
    marginRight: 3,
  },
});

export const HeaderDivider = chakra(Divider, {
  baseStyle: {
    borderColor: 'keylineGray',
    margin: '12px auto',
  },
});
