import { Box, Center, Divider, FlexRow, Grid } from '@/base-components';
import { chakra } from '@/utils';

export const FilterContainer = chakra(Grid, {
  baseStyle: {
    borderTop: '1px',
    borderBottom: '1px',
    borderColor: 'gray.400',
    gridColumnGap: 3,
    gridTemplateColumns: 'repeat(2, auto)',
    p: { base: '16px 20px', md: '16px 20px 16px 40px' },
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export const TextDivider = chakra(Divider, {
  baseStyle: {
    height: '18px',
    w: '1px',
    bg: 'darkGray',
  },
});

export const ButtonContainer = chakra(FlexRow, {
  baseStyle: {
    alignItems: { base: 'center', md: 'start' },
  },
});

export const CardContainer = chakra(Box, {
  baseStyle: {
    boxShadow: 'card',
    width: '100%',
  },
  label: 'CardContainer',
});

export const NoResultsContainer = chakra(Center, {
  baseStyle: {
    height: '100%',
  },
  label: 'NoResultsContainer',
});
