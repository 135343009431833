import { FilterIcon } from '@fluidtruck/icons';
import { Trans, useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import { useContext } from 'react';

import { Button } from '@/base-components';
import {
  Box,
  SpanText,
  SpinnerContainer,
  SubHeader,
  Text,
} from '@/base-components';
import { useResize } from '@/hooks';
import { CartContext, ItemsContext } from '@/lib/context';
import { heights } from '@/theme';

import ItemCard from './ItemCard';
import * as Styles from './itemListStyles';

const { cartBar } = heights;

const ItemContainer = ({ isLoading, showFilter, handleFilterClick }) => {
  const { t } = useTranslation(['common', 'search']);
  const { isDesktop } = useResize();

  const { items } = useContext(ItemsContext);
  const { cart } = useContext(CartContext);

  const count = items?.length || 0;

  return (
    <>
      <Box width="100%" data-test-id="item-container">
        {!showFilter && isDesktop && (
          <Styles.FilterContainer>
            <Text>
              <Trans t={t} i18nKey="search:searchResults.display" count={count}>
                Displaying <SpanText fontWeight="bold">{{ count }}</SpanText>{' '}
                Results
              </Trans>
            </Text>
            <Button
              data-test-id="filter-search"
              leftIcon={<FilterIcon h={5} w={5} />}
              variant="outline"
              sx={{ '> span': { marginInlineEnd: { base: 0, lg: '.5rem' } } }}
              w={{ base: 20 }}
              onClick={handleFilterClick}
            >
              {t('filter')}
            </Button>
          </Styles.FilterContainer>
        )}

        {!showFilter && !isLoading && (
          <Styles.CardContainer
            pb={{ base: cart ? `${cartBar}px` : 0, lg: 0 }}
            data-test-id="item-card-container"
          >
            {items?.map(item => (
              <ItemCard key={item.item.id} itemId={item.item.id} />
            ))}
            {items?.length === 0 && !isLoading && (
              <Styles.NoResultsContainer data-test-id="no-results">
                <SubHeader size={16}>{t('search:noResults')}</SubHeader>
              </Styles.NoResultsContainer>
            )}
          </Styles.CardContainer>
        )}
      </Box>

      {isLoading && <SpinnerContainer />}
    </>
  );
};

export default ItemContainer;

ItemContainer.propTypes = {
  isLoading: PropTypes.bool,
  showFilter: PropTypes.bool,
  handleFilterClick: PropTypes.func,
};
