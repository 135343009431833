import styled from '@emotion/styled';

import { Header } from '@/base-components';
import { generateMQ } from '@/utils/helpers';

const mqMax = generateMQ('max');

export const FilterContainer = styled.div(props => ({
  height: '100%',
  borderTop: `1px solid ${props?.theme?.colors?.gray[400]}`,
  width: '100%',
  overflow: 'auto',
  [mqMax[0]]: {
    borderTop: 'none',
  },
}));

export const InnerContainer = styled.div({
  margin: '0 16px',
});

export const FilterHeader = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
});

export const CloseContainer = styled.div({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  margin: '16px 24px 16px 0',
});

export const BookingContainer = styled.div(props => ({
  borderTop: `1px solid ${props?.theme?.colors?.gray[400]}`,
  margin: '0 24px 32px 0',
  [mqMax[0]]: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '0 0 32px 0',
  },
}));

export const MobileBookingContainerWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const MobileBookingContainer = styled.div({
  [mqMax[0]]: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
});

export const HeaderContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 24,
});

export const InstantContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  [mqMax[0]]: {
    marginBottom: 8,
  },
});

export const ClearApplyContainer = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  marginTop: '24px',
  [mqMax[0]]: {
    display: 'none',
  },
});

export const BodyContainer = styled.div({
  display: 'flex',
  maxWidth: 240,
});

export const ListingsContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-start',
  marginTop: 15,
});

export const PriceContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  marginBottom: 24,
  [mqMax[0]]: {
    justifyContent: 'center',
    marginTop: 32,
  },
});

export const ListingsHeader = styled(Header)({
  fontWeight: 'normal',
});

export const MobileButtonContainer = styled.div({
  display: 'none',
  [mqMax[0]]: {
    boxShadow:
      '0px 0px 6px rgba(0, 0, 0, 0.1), 0px -2px 8px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    backgroundColor: 'white',
    width: '100%',
    flexDirection: 'column',
    padding: '16px',
  },
});
