import styled from '@emotion/styled';
import { StarIcon } from '@fluidtruck/icons';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { HeaderLink, SubHeader } from '@/base-components';

const Spacer = styled.div({
  marginRight: 4,
  height: 20,
});

export const Rating = ({ rating, reviewCount }) => (
  <Grid item container xs>
    <Grid item>
      <Spacer>
        <StarIcon color="fluidBlue" />
      </Spacer>
    </Grid>
    <Grid item>
      <Spacer>
        <SubHeader size={16}>{rating}</SubHeader>
      </Spacer>
    </Grid>
    <Grid item>
      <Spacer>
        {/* TODO: Need a reservation reviews endpoint for this number */}
        <HeaderLink size={16}>({reviewCount})</HeaderLink>
      </Spacer>
    </Grid>
  </Grid>
);

export default Rating;

Rating.propTypes = {
  rating: PropTypes.number,
  reviewCount: PropTypes.number,
};

Rating.defaultProps = {
  rating: 0,
  reviewCount: 0,
};
