import styled from '@emotion/styled';
import { AppBar, Badge, Divider, Paper, Toolbar } from '@material-ui/core';

import { Body, Header } from '@/base-components';
import { generateMQ } from '@/utils/helpers';

const mq = generateMQ('max');

export const CartBar = styled(props => (
  <AppBar color="default" position="fixed" {...props} />
))(props => ({
  top: 'auto',
  bottom: 0,
  backgroundColor: props?.theme?.colors?.white,
  height: 88,
  alignItems: 'flex-end',
}));

export const CartMenuBar = styled(props => (
  <AppBar color="default" position="absolute" {...props} />
))(props => ({
  top: 0,
  bottom: 'auto',
  backgroundColor: props?.theme?.colors?.white,
  height: 50,
  alignItems: 'flex-end',
  boxShadow: 'none',
  borderWidth: '1px',
  borderColor: props?.theme?.colors?.gray[400],
  borderRadius: '10px 10px 0 0',
}));

export const VerticalDivider = styled(props => (
  <Divider orientation="vertical" {...props} />
))(props => ({
  backgroundColor: props?.theme?.colors?.gray[400],
  marginRight: 58,
  [mq[0]]: {
    marginRight: 30,
  },
}));

export const CartToolBar = styled(({ isMobile: _isMobile, ...props }) => (
  <Toolbar {...props} disableGutters />
))(props => ({
  height: 88,
  borderLeft: props.isMobile
    ? 'none'
    : `1px solid ${props?.theme?.colors?.gray[400]}`,
  [mq[0]]: {
    width: 'inherit',
  },
}));

export const CartBadge = styled(props => (
  <Badge
    color="primary"
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(props => ({
  '.MuiBadge-badge': {
    color: props?.theme?.colors?.white,
  },
}));

export const EstimateContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 75,
  marginRight: 16,
  [mq[0]]: {
    marginLeft: 0,
  },
});

export const EstimateText = styled(props => (
  <Body size={14} color="primary" {...props} />
))({
  textAlign: 'right',
});

export const EstimateAmount = styled(props => <Header size={24} {...props} />)({
  textAlign: 'right',
});

export const CartPopover = styled(props => <Paper {...props} elevation={3} />)({
  borderRadius: '10px 10px 0px 0px',
  background: '#fff',
  width: 408,
  padding: '16px 20px',
  overflow: 'auto',
});

export const ItemListContainer = styled.div({
  marginTop: 16,
  padding: '16px 0px',
  overflow: 'auto',
  maxHeight: 300,
  [mq[0]]: {
    maxHeight: 'unset',
    height: 'calc(100vh - 225px)',
    width: 'calc(100% - 32px)',
  },
  '::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
});

export const ButtonContainer = styled.div({
  minWidth: '120px',
  [mq[0]]: {
    minWidth: '80px',
  },
});
