import { useEffect, useRef, useState } from 'react';

export interface Return {
  geocoder?: google.maps.Geocoder;
  autocompleteService?: google.maps.places.AutocompleteService;
}

export const useGoogleLocationServices = (): Return => {
  const geocoder = useRef<google.maps.Geocoder>();
  const autocompleteService = useRef<google.maps.places.AutocompleteService>();
  const [isDone, setIsDone] = useState(false);

  useEffect(
    function initializeGoogleServices() {
      if (!window.google) return;
      setIsDone(true);
      geocoder.current = new google.maps.Geocoder();
      autocompleteService.current =
        new google.maps.places.AutocompleteService();
    },
    [window.google, isDone]
  );

  return {
    geocoder: geocoder.current,
    autocompleteService: autocompleteService.current,
  };
};
