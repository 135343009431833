import styled from '@emotion/styled';
import { InputLabel } from '@material-ui/core';

import { generateMQ } from '@/utils/helpers';

const mq = generateMQ('max');

export const searchFormStyles = {
  width: '100%',
  justifyContent: 'center',
  height: '100%',
  alignItems: 'center',
  position: 'relative',
  boxShadow: {
    base: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    lg: '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 6px rgba(0, 0, 0, 0.1)',
  },
  padding: { base: '16px 20px', lg: 0 },
};

export const TypeContainer = styled.div({
  width: 180,
  paddingRight: 16,
  [mq[0]]: {
    width: '100%',
    paddingRight: 0,
  },
});

export const LocationContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: 220,
  maxHeight: 48,
  margin: '0 16px',
  '& div div div': {
    zIndex: 20,
  },
  '& .MuiAutocomplete-root': {},
  '.MuiInput-underline:after': {
    borderBottom: 'none',
  },
  '& .MuiInputBase-input': {
    width: '100%',
  },
  '& .MuiAutocomplete-popupIndicator': {
    display: 'none',
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] ': {
    paddingBottom: 0,
  },
  [mq[0]]: {
    width: '100%',
    alignItems: 'normal',
    margin: 0,
  },
});

export const DeliveryContainer = styled.div({
  marginRight: 16,
});

export const LocationLabel = styled(InputLabel)(props => ({
  alignSelf: 'flex-start',
  fontFamily: props?.theme?.font,
  fontSize: 14,
  color: '#707070',
  lineHeight: 1.3,
}));

export const DtrpContainer = styled.div({
  width: 384,
  padding: '0 15px',
  [mq[0]]: {
    width: '100%',
    margin: 0,
    padding: '0',
  },
});
