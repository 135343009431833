import { Flex } from '@fluidtruck/core';
import { EVBadgeIcon, InstantBookIcon } from '@fluidtruck/icons';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import useSWR from 'swr';

import { ampli } from '@/amplitude';
import {
  Avatar,
  BoldText,
  Divider,
  Grid,
  GridItem,
  Header,
  ImageCarousel,
  SpinnerContainer,
  SubmitButton,
  Text,
  Title,
} from '@/base-components';
import Link from '@/base-components/Link';
import { SimpleVehicleLocationMap } from '@/components/SimpleVehicleMap/SimpleVehicleMap';
import { useOrganizationContext, useResize, useUser } from '@/hooks';
import { ViewInfo } from '@/hooks/use-items/types';
import { useReservationEstimate } from '@/lib/context';
import { Coordinates, GenericApiResponseV2, PublicItem } from '@/types';
import { distance } from '@/utils/conversions';
import { centsToDollars, formatMoney } from '@/utils/conversions';
import { get, scrollToRef } from '@/utils/helpers';
import { currentLocation } from '@/utils/location';
import { vehicleLocationMarker } from '@/utils/maps/map-icons/vehicle-location-marker';
import { svgToBase64 } from '@/utils/maps/svgToBase64';

import ReviewsContainer from '../VehicleDetail/ReviewsContainer';
import Rating from './Rating';
import {
  Details,
  DividerContainer,
  FlexContainer,
  ImageCarouselContainer,
  InnerFlexContainer,
  ItemDetailContainer,
  LoadingContainer,
  MapContainer,
  MileageContainer,
  SmallText,
  StyledDivider,
} from './styles';

// TODO - swap Tidal components
interface Props {
  id: number | null;
  updateDetailView: (args: ViewInfo) => void;
  isAvailable: boolean;
}

export const ItemDetail = ({ id, updateDetailView, isAvailable }: Props) => {
  const { user } = useUser();
  const { isDesktop } = useResize();
  const { t } = useTranslation(['common', 'messages', 'search']);
  const router = useRouter();
  const {
    context: {
      org: { organization },
    },
  } = useOrganizationContext();

  const [userLocation, setUserLocation] = useState<Coordinates | undefined>();

  const rootRef = useRef<HTMLDivElement | null>(null);
  const reviewsRef = useRef(null);

  const { data: itemData } = useSWR<GenericApiResponseV2<PublicItem>>(
    `api/publicitems/${id}`,
    get
  );

  const { reservationEstimate } = useReservationEstimate();

  const { data: reviewData } = useSWR(
    `api/reviews?id=${id}&type=reservation`,
    get
  );

  useEffect(() => {
    async function loadLocation() {
      const res = await currentLocation();
      setUserLocation(res);
    }

    loadLocation();
  }, []);

  useEffect(() => {
    if (isDesktop) {
      const ref = rootRef?.current;
      if (!ref) return;

      ref.scrollIntoView();
    }
  }, [id, isDesktop, rootRef]);

  const handleSubmitClick = (e: SyntheticEvent) => {
    ampli.vehicleDetailsReserve({
      userId: user?.id || undefined,
    });

    const { innerText } = e.target as HTMLElement;

    if (
      organization &&
      organization?.['borrow-limit'] <= 1 &&
      innerText === t('common:button.reserve')
    ) {
      router.push('/book');
      return;
    }

    updateDetailView({ type: 'reservation', detailViewId: id });
  };

  const rawLat = itemData?.data?.location?.coordinates?.lat?.toFixed(6); // || 39.752256;
  const rawLng = itemData?.data?.location?.coordinates?.lng?.toFixed(6); // || -104.995233;
  const lat = rawLat ? parseFloat(rawLat) : 39.752256;
  const lng = rawLng ? parseFloat(rawLng) : -104.995233;

  const pin = {
    url: svgToBase64({
      icon: vehicleLocationMarker(isAvailable),
    }),
  };
  const isElectric = itemData?.data?.['is-electric-vehicle'];
  return (
    <div ref={rootRef}>
      <ItemDetailContainer data-test-id="item-detail-container">
        <ImageCarouselContainer>
          {itemData?.data?.pictures && (
            <ImageCarousel
              images={(itemData?.data?.pictures || []).map(i => i?.standard)}
            />
          )}
          {!itemData?.data?.pictures && (
            <LoadingContainer>
              <SpinnerContainer />
            </LoadingContainer>
          )}
        </ImageCarouselContainer>
        <Title data-test-id="item-detail-title">{itemData?.data?.title}</Title>
        <FlexContainer>
          <InnerFlexContainer>
            <Header size={32}>
              ${centsToDollars(reservationEstimate?.item?.rates?.hourly || 0)}
            </Header>
            <DividerContainer>
              <StyledDivider
                orientation="vertical"
                style={{ margin: '0 4px', height: 17 }}
              />
            </DividerContainer>
            <SmallText size={16}>hour</SmallText>
          </InnerFlexContainer>
          <InnerFlexContainer>
            <Header size={32}>
              ${centsToDollars(reservationEstimate?.item?.rates?.daily || 0)}
            </Header>
            <DividerContainer>
              <StyledDivider
                orientation="vertical"
                style={{ margin: '0 4px', height: 17 }}
              />
            </DividerContainer>
            <SmallText size={16}>day</SmallText>
          </InnerFlexContainer>
          <InnerFlexContainer>
            <Header size={32}>
              ${centsToDollars(reservationEstimate?.item?.rates?.weekly || 0)}
            </Header>
            <DividerContainer>
              <StyledDivider
                orientation="vertical"
                style={{ margin: '0 4px', height: 17 }}
              />
            </DividerContainer>
            <SmallText size={16}>week</SmallText>
          </InnerFlexContainer>
        </FlexContainer>
        <MileageContainer>
          {reservationEstimate?.item?.details?.unlimited_miles ? (
            <SmallText size={16}>{t('common:unlimitedMileage')}</SmallText>
          ) : (
            <>
              <SmallText size={16}>
                {t('search:includedMilage', {
                  mileage:
                    reservationEstimate?.item?.details?.mileage_limit_daily ||
                    0,
                })}
              </SmallText>
              <DividerContainer>
                <StyledDivider orientation="vertical" style={{ height: 17 }} />
              </DividerContainer>
              <SmallText size={16}>
                {formatMoney(
                  centsToDollars(
                    reservationEstimate?.item?.details?.cost_per_mile || 0
                  )
                )}{' '}
                {t('search:perAdditionalMile')}
              </SmallText>
            </>
          )}
        </MileageContainer>
        <Details templateColumns={isElectric ? 'auto 1fr' : 'auto'}>
          {isElectric && (
            <Flex gap={2} alignItems="center">
              Electric Vehicle
              <EVBadgeIcon h={5} w={5} />
            </Flex>
          )}
          {itemData?.data?.instabook && (
            <Flex gap={2} alignItems="center">
              {t('common:instantBook')}
              <InstantBookIcon color="fluidBlue" h={5} w={5} />
            </Flex>
          )}
        </Details>
        <SubmitButton
          mt={{ base: 3, md: 0 }}
          width={{ base: '100%', lg: 'fit-content' }}
          isDisabled={!isAvailable}
          onClick={handleSubmitClick}
        >
          {isAvailable
            ? t('common:button.reserve')
            : t('common:button.unavailable')}
        </SubmitButton>

        <Divider my={8} color="gray.400" />

        <Flex gap={2} alignItems="center">
          <Avatar src={itemData?.data?.lender?.['avatar-url']} />
          <BoldText fontSize="lg">{itemData?.data?.lender?.first}</BoldText>
          {reviewData?.data?.entries?.length > 0 && (
            <div
              style={{ cursor: 'pointer' }}
              role="presentation"
              onClick={() => scrollToRef(reviewsRef)}
            >
              <Rating
                rating={itemData?.data?.lender?.['average-rating']}
                reviewCount={reviewData?.data?.entries?.length}
              />
            </div>
          )}
        </Flex>

        <Grid gap={3} mt={6}>
          <Title fontSize="3xl">Overview</Title>
          <Text>{itemData?.data?.description}</Text>
          <Title mt="3" fontSize="3xl">
            Pick Up / Drop Off Location
          </Title>
          {lat && lng && (
            <MapContainer>
              <SimpleVehicleLocationMap
                center={{ lat, lng }}
                icon={{ ...pin }}
              />
            </MapContainer>
          )}
          <GridItem>
            <Text>
              This vehicle is{' '}
              <strong>
                {`${distance(
                  itemData?.data?.location?.coordinates as Coordinates,
                  userLocation
                ).toFixed(2)} miles`}
              </strong>{' '}
              away
            </Text>
            <Link
              fontSize="lg"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              href={
                itemData?.data?.location?.address &&
                `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  itemData?.data?.location?.address
                )}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {itemData?.data?.location?.address}
            </Link>
          </GridItem>
          {reviewData?.data?.entries?.length > 0 && (
            <Grid data-test-id="reviews" mt={6}>
              <Flex>
                <Header size={24} style={{ marginRight: 10 }}>
                  Reviews
                </Header>
                <Rating
                  rating={itemData?.data?.lender?.['average-rating']}
                  reviewCount={reviewData?.data?.entries?.length}
                />
              </Flex>
              <div ref={reviewsRef}>
                <ReviewsContainer
                  noHeader
                  reviewInfo={reviewData?.data?.entries}
                />
              </div>
            </Grid>
          )}
        </Grid>
      </ItemDetailContainer>
    </div>
  );
};

export default ItemDetail;
